<template>
    <section-block>
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6">
                    <h1 class="text-responsive">
                        Easiest way to use home equity
                    </h1>
                    <h5 class="text-responsive fw-light">
                        Using your home as collateral, like a mortgage, we can get you home equity rates, all in as fast as 15 minutes and entirely online.
                    </h5>
                </div>
                <div class="col-12 col-md-6 mt-5 mt-md-0">
                    <img
                        src="@/assets/images/pages/marketing/landing/EasyEquityHome@2x.jpg"
                        class="img-fluid"
                        alt="Easiest way to use home equity"
                    >
                </div>
            </div>
            <div class="row mt-md-9">
                <div class="col-12 col-md-6 mt-5 mt-md-0">
                    <h5 class="text-responsive">
                        Perfect for home improvements
                    </h5>
                    <p class="text-responsive">
                        Broken pipe? Bathroom upgrade? Use our fast and simple access to your home equity to start that home improvement project you have wanted to do. Big or small.
                    </p>
                </div>
            </div>
            <div class="row mt-md-5 align-items-center">
                <div class="col-12 col-md-6 mt-5 mt-md-0">
                    <div
                        id="useCaseCarousel"
                        class="carousel slide"
                        data-ride="carousel"
                    >
                        <div class="carousel-inner rounded-3">
                            <div class="carousel-item active">
                                <img
                                    src="@/assets/images/pages/marketing/landing/FreshPaint@2x.jpg"
                                    class="d-block w-100"
                                    alt="Deduct interest on your home projects"
                                >
                            </div>
                            <div class="carousel-item">
                                <img
                                    src="@/assets/images/pages/marketing/landing/UpdateKitchen@2x.jpg"
                                    class="d-block w-100"
                                    alt="Deduct interest on your home projects"
                                >
                            </div>
                            <div class="carousel-item">
                                <img
                                    src="@/assets/images/pages/marketing/landing/NewYard@2x.jpg"
                                    class="d-block w-100"
                                    alt="Deduct interest on your home projects"
                                >
                            </div>
                            <div class="carousel-item">
                                <img
                                    src="@/assets/images/pages/marketing/landing/HomeRepairs@2x.jpg"
                                    class="d-block w-100"
                                    alt="Deduct interest on your home projects"
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-5 mt-md-0">
                    <h5 class="text-responsive">
                        Tax savings on your home projects
                    </h5>
                    <p class="text-responsive">
                        Did you know home improvement projects may be considered tax deductible! Consult a tax advisor regarding the deductibility of interest and charges.
                    </p>
                </div>
            </div>
        </div>
    </section-block>
</template>

<script>
    import SectionBlock from '@/components/SectionBlock'
    import { Carousel } from 'bootstrap'

    export default {
        name: 'EasyHomeEquitySection',
        components: {
            SectionBlock,
        },
        mounted: function () {
            const myCarousel = document.getElementById('useCaseCarousel')
            const carousel = new Carousel(myCarousel, {
                interval: 1500,
            })
            carousel.cycle()
        },
    }
</script>
