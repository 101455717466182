<template>
    <marketing-layout :has-landing-footer="true">
        <div class="container-fluid px-0">
            <section-block class="pb-sm-1 pt-4 pt-md-8">
                <div
                    id="origination-form-container"
                    class="container"
                >
                    <div class="row">
                        <div class="col-12">
                            <section-header variant="text-primary">
                                Low Variable APRs<sup><a href="#footnote_1">1</a></sup>
                            </section-header>
                            <h1 class="mb-6">
                                <img
                                    class="img-fluid"
                                    :src="require(`@/assets/images/experiments/${heraclesParameter.EXPERIMENT_NAME}/apr.svg`)"
                                    :alt="$t(`experiments.${heraclesParameter.EXPERIMENT_NAME}.aprAltText`)"
                                    width="462"
                                >
                            </h1>
                            <h1 class="text-responsive mb-3">
                                Home equity rates. <br class="md lg xl">
                                On a credit card.
                            </h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-7">
                            <h2 class="heading-5 text-responsive fw-light mb-3">
                                Get rates like a home equity line of credit with the ease of a Visa credit card. Plus 1.5% unlimited cashback. Decision in minutes. 100% online.<br class="xl">
                                $0 to get a card.<sup><a href="#footnote_1">1</a></sup>
                            </h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-5">
                            <origination-invite-form
                                id-prefix="main-origination-form"
                                :code-required="codeRequired"
                                :show-forgot-code-option="showForgotCodeOption"
                                :mail-offer-link="{ name: 'invite', path: '/invite' }"
                                ref="originationInviteFormHero"
                                show-safe-credit-score
                                cta-text="Check your offer"
                            />
                        </div>
                    </div>
                </div>
                <div class="container mt-5 mt-md-7 text-center">
                    <section-header variant="text-primary fw-bold">
                        As featured on
                    </section-header>
                    <div class="row justify-content-md-center mt-2">
                        <div class="col col-md-4 col-lg-3 mt-3">
                            <img
                                src="@/assets/images/global/bankrate_logo.jpg"
                                alt="BankRate"
                                class="partner-logo"
                                width="162"
                            >
                        </div>
                        <div class="col col-md-4 col-lg-3 mt-3">
                            <img
                                src="@/assets/images/global/trustpilot_logo.jpg"
                                alt="Trust Pilot"
                                class="partner-logo"
                                width="197"
                            >
                        </div>
                        <div class="col col-md-4 col-lg-3 mt-3">
                            <img
                                src="@/assets/images/global/lendingtree_logo.jpg"
                                alt="Lending Tree"
                                class="partner-logo"
                                width="171"
                            >
                        </div>
                    </div>
                </div>
            </section-block>

            <div class="mt-lg-7">
                <div
                    class="position-relative"
                    style="z-index: 1000"
                    @click="$refs.videoPlayer.toggleVisibility()"
                    @keydown="$refs.videoPlayer.toggleVisibility()"
                >
                    <video-player-play-button />
                    <img
                        src="@/assets/images/pages/marketing/landingV2_5/video-still.jpg"
                        class="w-100 img-fluid"
                        alt="Aven Card"
                    >
                </div>
                <video-player
                    name="sandwich_349"
                    src="https://www.youtube.com/embed/jiK3m6lm20M?autoplay=1&mute=0&color=white&fs=0&modestbranding=1&rel=0&showinfo=0&enablejsapi=1&origin=https%3A%2F%aven.com"
                    ref="videoPlayer"
                />
            </div>

            <what-is-it-section />

            <div class="row d-md-none">
                <div class="col-12">
                    <img
                        src="@/assets/images/pages/marketing/landing/aven-credit-card-reader@2x.jpg"
                        class="img-fluid"
                        alt="Aven Card"
                    >
                </div>
            </div>

            <reviews-section theme="trustpilot" />

            <how-it-works-section class="bg-light" />

            <card-comparison-section />

            <ticker-section />

            <easy-home-equity-section class="bg-light" />

            <limits-section line-size-limit="$100K" />

            <unlimited-cashback-section />

            <community-stats-section class="bg-light" />

            <fees-section />

            <cash-out-section />

            <benefits-section class="bg-light" />

            <about-us-section />

            <fixed-payments-section />

            <faq-section class="bg-light" />

            <section-block>
                <div class="container">
                    <div class="row gy-3">
                        <div class="col-12 col-md-6">
                            <div class="bg-light rounded-3 px-3 py-5 px-md-5 py-md-7">
                                <img
                                    src="@/assets/images/pages/marketing/landing/CreditCardIcon.svg"
                                    width="48"
                                    height="48"
                                    alt="Contact Us"
                                    class="mb-3"
                                >
                                <h3 class="mb-3">
                                    Check your offer. <br>
                                    $0 to get a card.
                                </h3>
                                <h5 class="mb-3 fw-light">
                                    This will <strong>NOT</strong> affect your credit score
                                </h5>
                                <origination-invite-form
                                    id-prefix="footer-origination-form"
                                    class="mw-invite-form"
                                    :code-required="codeRequired"
                                    :show-forgot-code-option="showForgotCodeOption"
                                    :mail-offer-link="{ name: 'invite', path: '/invite' }"
                                    ref="originationInviteFormFooter"
                                    :show-safe-credit-score="false"
                                    cta-text="Check your offer"
                                />
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="bg-light rounded-3 px-3 py-5 px-md-5 py-md-7 h-100">
                                <img
                                    src="@/assets/images/pages/marketing/landing/PhoneIcon.svg"
                                    width="48"
                                    height="48"
                                    alt="Contact Us"
                                    class="mb-3"
                                >
                                <h3 class="mb-3">
                                    Reach out with<br>
                                    any questions
                                </h3>
                                <h5
                                    class="mb-3 fw-light"
                                    v-html="$t('pages.marketing.landing.reengage.contactInfoHtml')"
                                />
                                <a
                                    :href="smsHref"
                                    class="btn btn-secondary w-100"
                                >
                                    {{ $t('pages.marketing.landing.reengage.messageUs') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section-block>
        </div>
    </marketing-layout>
</template>

<script>
    import Marketing from '@/layouts/Marketing'
    import { i18n } from '@/utils/i18n'
    import SectionBlock from '@/components/SectionBlock'
    import { contact_info } from '@/utils/contact-info-dictionary'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import { Flows } from '@/flow/flowController'
    import SectionHeader from '@/components/SectionHeader'
    import OriginationInviteForm from '@/components/OriginationInviteForm'
    import { experimentsMixin } from '@/mixins/experimentsMixin'
    import CardComparisonSection from '@/components/landing/CardComparisonSection'
    import AboutUsSection from '@/components/landing/AboutUsSection'
    import { logger } from '@/utils/logger'
    import WhatIsItSection from '@/components/landing/WhatIsItSection'
    import ReviewsSection from '@/components/landing/ReviewsSection'
    import CommunityStatsSection from '@/components/landing/CommunityStatsSection'
    import FixedPaymentsSection from '@/components/landing/FixedPaymentsSection'
    import VideoPlayerPlayButton from '@/components/VideoPlayerPlayButton'
    import VideoPlayer from '@/components/VideoPlayer'
    import HowItWorksSection from '@/components/landing/HowItWorksSection'
    import EasyHomeEquitySection from '@/components/landing/EasyHomeEquitySection'
    import LimitsSection from '@/components/landing/LimitsSection'
    import UnlimitedCashbackSection from '@/components/landing/UnlimitedCashbackSection'
    import FeesSection from '@/components/landing/FeesSection'
    import CashOutSection from '@/components/landing/CashOutSection'
    import BenefitsSection from '@/components/landing/BenefitsSection'
    import FaqSection from '@/components/landing/FaqSection'
    import { reloadPageWithCleanState } from '@/utils/routerUtils'
    import TickerSection from '@/components/landing/TickerSection'

    const SITE_VERSION = '2.5'
    export default {
        name: 'Start100KLanding',
        metaInfo: {
            title: 'Aven HELOC Card: A Credit Card backed by Home Equity',
            meta: [{ name: 'description', content: String(i18n.t('pages.marketing.landing.description')) }],
        },
        props: {
            codeRequired: {
                type: Boolean,
                required: true,
            },
            showForgotCodeOption: {
                type: Boolean,
                required: true,
            },
        },
        components: {
            'ticker-section': TickerSection,
            'faq-section': FaqSection,
            'benefits-section': BenefitsSection,
            'cash-out-section': CashOutSection,
            'fees-section': FeesSection,
            'unlimited-cashback-section': UnlimitedCashbackSection,
            'limits-section': LimitsSection,
            'easy-home-equity-section': EasyHomeEquitySection,
            'how-it-works-section': HowItWorksSection,
            'fixed-payments-section': FixedPaymentsSection,
            'community-stats-section': CommunityStatsSection,
            'what-is-it-section': WhatIsItSection,
            'about-us-section': AboutUsSection,
            'card-comparison-section': CardComparisonSection,
            'reviews-section': ReviewsSection,
            'origination-invite-form': OriginationInviteForm,
            'section-header': SectionHeader,
            'section-block': SectionBlock,
            'marketing-layout': Marketing,
            'video-player-play-button': VideoPlayerPlayButton,
            'video-player': VideoPlayer,
        },
        mixins: [experimentsMixin],
        created: function () {
            appSessionStorage.setItem(sessionStorageKey.currentFlow, Flows.origination)
        },
        mounted: function () {
            if (appSessionStorage.getItem(sessionStorageKey.jwtTokens)) {
                logger.info(`User went to landing page with jwt tokens! Clearing their stale state`)
                return reloadPageWithCleanState()
            }

            this.logViewEvent()
        },
        computed: {
            smsHref() {
                return `sms:${contact_info.sms}`
            },
        },
        methods: {
            logViewEvent: function () {
                this.$logEvent('view_landing_default', { query: window.location.search, version: SITE_VERSION })
            },
        },
    }
</script>

<style lang="scss">
    @import '../../../../styles/pages/marketing/landing';
</style>
